import { EventSectionsCartModel } from '../components/event-sections/EventSectionsForm';
import {
  Event,
  EventSection
} from '../components/members/NewApiTypes.generated';
import * as dateFns from 'date-fns';
import { getUserInfo } from '../util/auth';

export type EventSectionType = 'CONFERENCE_DAY' | 'SERIES';

export const getEventSectionType = (
  eventSection?: Partial<EventSection>
): EventSectionType =>
  eventSection?.group?.eventPath?.event?.eventType?.name === 'Conference Day'
    ? 'CONFERENCE_DAY'
    : 'SERIES';

export const getEventSectionIdentifier = (eventSection: EventSection): string =>
  getEventSectionType(eventSection) === 'CONFERENCE_DAY'
    ? eventSection.id
    : eventSection.group?.eventPath?.event?.id || '';

export const navigateToEventSectionDetails = (e: any) => {
  switch (e.type) {
    case 'CONFERENCE_DAY':
      return `/conference-days/${e.id}`;
    case 'SERIES':
      return `/series/${e.id}`;
    default:
      return `/events/${e.slug}`;
  }
};

interface isNotAvailableForPurchase {
  eventSection?: Partial<EventSection>;
  event?: Event;
  type: 'EVENT' | 'CONFERENCE_DAY' | 'SERIES';
  isImpersonated?: boolean;
}

export const hasOtherEventTypeInCart = (
  cart: EventSectionsCartModel,
  eventSection: EventSection
) => {
  const eventSectionType = getEventSectionType(eventSection);

  return cart.items.some(item => item.type !== eventSectionType);
};

export const isNotAvailableForPurchase = ({
  eventSection,
  event,
  type,
  isImpersonated = false
}: isNotAvailableForPurchase): boolean => {
  if (!event?.daysBeforePurchaseClose || isImpersonated) {
    return false;
  }

  const today = new Date();

  // For the Calendar of Conference Days event only, let's remove the autoclose feature for now.

  // if (type === 'CONFERENCE_DAY') {
  //   const lastDate = dateFns.max(
  //     eventSection?.dates?.map(date => new Date(date as any)) as Date[]
  //   );

  //   if (
  //     dateFns.differenceInDays(lastDate, today) < event.daysBeforePurchaseClose
  //   ) {
  //     return true;
  //   }

  //   return false;
  // }

  if (type === 'SERIES' || type === 'EVENT') {
    let lastEventDate = null;

    if (event.isRangeDate) {
      lastEventDate = event.endDate;
    } else {
      lastEventDate = dateFns.max(
        event.dates.map(date => new Date(date as any))
      );
    }

    if (!lastEventDate) {
      return false;
    }

    const lastEventDateParsed = new Date(lastEventDate);

    if (
      dateFns.differenceInDays(lastEventDateParsed, today) <
      event.daysBeforePurchaseClose
    ) {
      return true;
    }

    return false;
  }

  return false;
};

export const getTicketAllocationNotAvailableMessage = (
  daysBeforePurchaseClose: any
) =>
  `Ticket allocations cannot be made less than ${daysBeforePurchaseClose} before event`;
